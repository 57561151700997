@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;800;900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.Baskervile {
  font-family: 'Libre Baskerville', serif !important;
}

.Roboto {
  font-family: 'Roboto', sans-serif !important;
}

.Inter {
  font-family: 'Inter', sans-serif !important;
}

* {
  margin: 0;
}

@media (max-width: 900px) {
  .rotate90 {
    transform: rotate(90deg);
  }

  .ApplicationProcessYellowDot {
    top: -8px;
    left: 0;
  }
}

@media (min-width: 901px) {
  .ApplicationProcessYellowDot {
    top: 0;
    left: -4px;
  }
}
